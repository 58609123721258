import { Millisecond } from '../../../models';
import { DestroyRef, Inject, Injectable, InjectionToken } from '@angular/core';
import {
  asyncScheduler,
  delay,
  distinctUntilChanged,
  Observable,
  of,
  OperatorFunction,
  pipe,
  scan,
  SchedulerLike,
  Subject,
  switchMap
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface ShowOptions {
  id: string;
  msg: string | null;
  delay: Millisecond;
  backgroundOpacity: number;
}

type SpinnerShowAction = ShowOptions & { action: 'SHOW' };
type SpinnerHideAction = { id: string, spinnerId?: string | undefined, action: 'HIDE' };
type SpinnerAction = SpinnerShowAction | SpinnerHideAction;

const DEFAULT_SHOW_OPTIONS: ShowOptions = {
  id: '_DEFAULT',
  delay: 0,
  backgroundOpacity: 0.9,
  msg: null,
};

export const SCHEDULER_TOKEN = new InjectionToken <SchedulerLike>('SCHEDULER_TOKEN', {
  providedIn: 'root',
  factory: (): SchedulerLike => asyncScheduler,
});

@Injectable({
  providedIn: 'root',
})
export class RsLoaderService {

  /**
   * The showSpinner observable is used to display or not a global spinner on the page.
   *
   * A. It starts with an observable emitting all 'show' and 'hide' actions requested to the service.
   *    Every action is linked to an ID to avoid concurrency issues.
   *    If no ID is provided, a default value is used.
   *
   * B. Then it folds to a single state if at least 1 spinner is active
   *
   * C. Finally it adds a delay only for 'switch ON' transitions within a switchMap, ensuring switch OFF always cancels any switch ON (even if the ON is not present yet)
   *
   * Visually, we can display the 3 stages as follows (timeline going from left to right) if we consider a delay of 10:
   *
   *
   *    time : ----------------------------------------------------------------------------------------------->
   *
   * actions :          1-ON        1-OFF               2-ON  3-ON  3-OFF             2-OFF   4-ON  4-OFF
   * A       :                                                  _______
   *                     ______________                    ____|      |___________________       _______
   *           _________|             |___________________|                              |______|      |________
   *
   * B       :           ______________                    _______________________________       _______
   *           _________|             |___________________|                              |______|      |________
   *
   * C       :                     ____                              _____________________
   *           ___________________|   |_____________________________|                    |______________________
   */
  public readonly showSpinner: Observable<boolean>;
  private readonly _spinnerAction$ = new Subject<SpinnerAction>();
  private _backgroundOpacity: number = 0;
  private _currentMessage: string | null = '';

  /** Observable triggered on spinner hide (if spinnerId provided)
   *
   * @Usage If you want to trigger an event after a specific spinner is hidden.
   * Ex: display rsMessagesHandlerService.showSuccessMsg only after spinner hide when request done
   *
   * @return spinnerId string
   */
  public get $spinnerHided(): Observable<string> {
    return this._spinnerAction$.pipe(
      filter(spinnerAction => spinnerAction.action === 'HIDE'),
      map(spinnerAction => spinnerAction as SpinnerHideAction),
      filter(spinnerHideAction => !!spinnerHideAction.spinnerId),
      map(spinnerHideAction => spinnerHideAction.spinnerId!),
      delay(500),
    );
  }

  /** Returns the opacity chose. */
  public get backgroundOpacity(): number {
    return this._backgroundOpacity;
  }

  /** Returns the current displayed message underneath the icon. */
  public get currentMessage(): string | null {
    return this._currentMessage;
  }

  constructor(destroyRef$: DestroyRef, @Inject(SCHEDULER_TOKEN) scheduler: SchedulerLike) {
    this.showSpinner = this._spinnerAction$.pipe(
      foldToGlobalSpinnerState(),
      switchMap(showSpinner => of(showSpinner).pipe(delay(showSpinner ? 300 : 0, scheduler))),
      distinctUntilChanged(),
    );
    this._spinnerAction$.pipe(
      takeUntilDestroyed(destroyRef$),
      filter(spinnerAction => spinnerAction.action === 'SHOW'),
      map(spinnerAction => spinnerAction as SpinnerShowAction),
    ).subscribe(spinnerAction => {
      this._backgroundOpacity = spinnerAction.backgroundOpacity;
      this._currentMessage = spinnerAction.msg;
    });
  }

  /**
   * Shows the spinner on the page.
   *
   * @param options.msg OPTIONAL - Default null -- The message to display underneath the spinner icon, must be translation key.
   *
   * @param options.backgroundOpacity OPTIONAL - Default = 0.9 -- Opacity of the background
   *
   * @param options.delay OPTIONAL - Default = 0ms -- Delay is in millisecond. Used to avoid flickering when hide is called to fast
   */
  public show(options?: Partial<ShowOptions>): void {
    this._spinnerAction$.next({ action: 'SHOW', ...DEFAULT_SHOW_OPTIONS, ...options });
  }

  /** Hide spinner. */
  public hide(param?: { id: string } | string): void {
    let id = DEFAULT_SHOW_OPTIONS.id;
    let spinnerId: string | undefined;
    if (typeof param === 'string') {
      spinnerId = param;
    } else if (typeof param === 'object') {
      id = param.id;
    }
    this._spinnerAction$.next({ action: 'HIDE', id, spinnerId });
  }
}

const foldToGlobalSpinnerState: () => OperatorFunction<SpinnerAction, boolean> = () =>
  pipe(
    scan<SpinnerAction, Set<string>>(
      (activeSpinners, spinnerAction) => {
        if (spinnerAction.action === 'SHOW') {
          activeSpinners.add(spinnerAction.id);
        } else {
          activeSpinners.delete(spinnerAction.id);
        }
        return activeSpinners;
      },
      new Set<string>()
    ),
    map(activeSpinners => activeSpinners.size > 0)
  );
