import { takeUntil } from 'rxjs/operators';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FileUploaderService } from './file-upload-service/file-uploader.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import * as uuid from 'uuid';
import { HttpProgressEvent, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Nullable, RsFile } from '../../models';
import { RsMessagesHandlerService } from '../rs-messages-handler/services/rs-messages-handler.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DropzoneDirective } from './drop-zone-directive/dropzone.directive';
import { isEmpty } from 'lodash';

export interface UploaderDoneEvent {
  done: boolean;
  withErrors: boolean;
}

const MIME_TYPES_CONST = {
  'application/vnd.ms-excel': 'XLS',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
  'application/pdf': 'PDF',
  'image/png': 'PNG',
  'image/jpeg': 'JPEG'
} as const;

type MimeTypes = keyof typeof MIME_TYPES_CONST;

/** Usage Example
 *
 * ```html
 <rs-file-uploader
 allowMultiUpload (if propertie is present will allow multi upload files in a queue)
 #deliveryReceipt
 showUploadedFiles (if propertie is present will show document once uploaded instead of removing them)
 [acceptedFileTypes]="['application/pdf', 'image/png', 'image/jpeg']"
 maxFilesize="100000"
 (isReadyToUpload)="deliveryReceipt_ReadyToUpload=$event"
 [disableUploader]="BOOLEAN"
 >
 </rs-file-uploader>
 ```
 */

@Component({
  selector: 'rs-file-uploader',
  templateUrl: './rs-file-uploader.component.html',
  styleUrls: ['./rs-file-uploader.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    DropzoneDirective
  ],
  providers: [FileUploaderService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RsFileUploaderComponent implements OnInit, OnDestroy {
  @HostBinding('class') @Input('class') public class?: string;
  @HostBinding('id') @Input('id') public id?: string;
  /** (OPTIONAL) This description will be sent with the file if provided.
   *
   * default: null
   */
  @Input() public fileDescription?: string | null = null;
  /** (OPTIONAL) The default implementation of accept checks the file's mime type or extension against this list.
   *
   * Default: null
   *
   * This is a string array comma separated list of mime types or file extensions.
   *
   * E.g.: ['image/png', 'image/jpeg','application/pdf']
   */
  @Input() public acceptedFileTypes?: MimeTypes[] | null = null;
  /** (OPTIONAL) If not null defines the max TOTAL size for all files in KB
   *
   * Default: 15000KB
   */
  @Input() public maxFilesize?: number = 15000;
  /** (Optional) Defines max size per size in KB
   *
   * /!\ Multiple Upload must be activated.
   */
  @Input() public maxSizePerFile?: number | null = null;
  /** (Optional) Defines maximum number of files
   *
   * /!\ Multiple Upload must be activated.
   */
  @Input() public maxFiles?: number | null = null;
  /** (OPTIONAL) Disable file upload
   *
   * default: false
   */
  @Input() public disableUploader = false;
  /** Emits whenever the list of (valid) uploaded files changes (uploading a new file & removing an uploaded file) */
  @Output() public filesChange = new EventEmitter<RsFile[]>();
  /** Emits whenever a file is removed from the list of (valid) uploaded files */
  @Output() public fileRemoved = new EventEmitter<RsFile>();
  /** Emits whenever a file is added from the list of (valid) uploaded files */
  @Output() public fileAdded = new EventEmitter<RsFile>();
  /** Emits true when batch is ready to upload else false
   *
   * @emit boolean
   */
  @Output() public isReadyToUpload: BehaviorSubject<boolean>;
  /** Emits uploading status
   *
   * @emit boolean
   */
  @Output() public isUploading: BehaviorSubject<boolean>;
  /** Emits RsFile uploaded successfully
   *
   * @emit boolean
   */
  @Output() public fileUploadEndedEmitter: EventEmitter<RsFile>;
  /** Emits RsFile upload error
   *
   * @emit boolean
   */
  @Output() public fileUploadErrorEmitter: EventEmitter<RsFile>;
  /** Emits when batch ended
   *
   * @emit boolean
   */
  @Output() public uploadDone: EventEmitter<UploaderDoneEvent>;
  @ViewChild('errorDialog', {
    read: TemplateRef,
    static: false
  }) public errorDialog!: TemplateRef<MatDialog>;
  public isHovering?: boolean;
  public displayFiles: RsFile[] = []; // Keep all files for and show status. Erased when new drop
  public filesNotAllowedToUploadList: RsFile[] = [];
  public filesValidatedToUploadList: RsFile[] = []; // Keeps file to reupload in case of error and erase succesfully uploaded
  public inputFileUid: string = uuid.v4();
  /** Will hide trash button when upload handled by parent.
   *
   * Has to be handled by parent coponent.
   */
  public isExternalUploading?: boolean;
  public errorDialogRef!: MatDialogRef<MatDialog>;
  public enumMimeTypes = MIME_TYPES_CONST;
  public maxTotalFilesizeDisplay!: string;
  public maxFileSizePerFileDisplay!: string;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('fileInput', { static: false }) private fileInput!: ElementRef;
  private batchEndCounter = 0;
  private maxFileSizeInBytes!: number;
  private maxSizePerFileInBytes!: number;
  private maxTotalFiles!: number;
  private currentBatchSizeInBytes: number;
  private displayErrorDialog = false;
  private killAllRequests$: Subject<boolean> = new Subject<boolean>(); // Use to kill all uploads in once
  private translations?: { [key: string]: string };
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    public dialog: MatDialog,
    public fileUploaderService: FileUploaderService,
    private readonly rsMessagesHandlerService: RsMessagesHandlerService,
    private readonly translateService: TranslateService
  ) {
    this.isReadyToUpload = new BehaviorSubject(false as boolean);
    this.isUploading = new BehaviorSubject(false as boolean);
    this.currentBatchSizeInBytes = 0;
    this.fileUploadEndedEmitter = new EventEmitter<RsFile>();
    this.fileUploadErrorEmitter = new EventEmitter<RsFile>();
    this.uploadDone = new EventEmitter<{
      done: false;
      withErrors: false;
    }>() as EventEmitter<UploaderDoneEvent>;
  }

  /** If true Doesn't remove files uploaded from the list to show that they were successfully uploaded
   *
   *
   *
   * default: false
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() public _showUploadedFiles = false;

  /** (OPTIONAL) If true Doesn't remove files uploaded from the list to show that they where succesfully uploaded
   *
   * `Have coerceBooleanProperty` meaning that if you had showUploadedFiles without a value it equals true
   */
  @Input()
  public set showUploadedFiles(value: boolean | string) {
    this._showUploadedFiles = coerceBooleanProperty(value);
  }

  /** (OPTIONAL) If true show upload progress
   *
   * Default: false
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() public _showUploadProgress = false;

  /** showProgress setter
   *
   * `Have coerceBooleanProperty` meaning that if you had showUploadedFiles without a value it equals true
   */
  @Input()
  public set showUploadProgress(value: boolean | string) {
    this._showUploadProgress = coerceBooleanProperty(value);
  }

  /** If true show eventual error message
   *
   * Default: false
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() public _showErrorsWithRsMessageHandler = false;

  /** Display BE errors using RsMessageHandler
   *
   * `Have coerceBooleanProperty` meaning that if you had showErrorsWithRsMessageHandler without a value, it's concidered like true
   */
  @Input()
  public set showErrorsWithRsMessageHandler(value: boolean | string) {
    this._showErrorsWithRsMessageHandler = coerceBooleanProperty(value);
  }

  /** Allow multiple files
   *
   * `Optional`
   *
   * `default: false`
   */
  private _allowMultiUpload: boolean = true;

  /** Allow multiple files setter
   *
   * `Have coerceBooleanProperty` meaning that if you had allowMultiUpload without a value, it's concidered like true
   */
  @Input()
  public get allowMultiUpload(): boolean {
    return this._allowMultiUpload;
  }

  public set allowMultiUpload(value: BooleanInput) {
    this._allowMultiUpload = coerceBooleanProperty(value);
  }

  @Input()
  public set initialFiles(files: RsFile[]) {
    this.displayFiles = files;
    this.filesValidatedToUploadList = files;
    if (!isEmpty(files)) {
      this.isReadyToUpload.next(true);
    }
  }

  public ngOnInit(): void {
    this.maxTotalFilesizeDisplay = this.displayBytesLabels(this.maxFilesize!);
    this.maxFileSizePerFileDisplay = this.displayBytesLabels(this.maxSizePerFile!);
    // Set here so it takes the this.maxFilesize value into account if provided as a param
    this.maxFileSizeInBytes = this.transformMBToBytes((this.maxFilesize! / 1000));
    this.maxSizePerFileInBytes = this.transformMBToBytes((this.maxSizePerFile! / 1000));
    this.maxTotalFiles = this.maxFiles!;

    this.translateService
      .stream(['FILE_UPLOAD_COMPONENT.FILE_UPLOAD_FAILURE'])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  /** Reset file uploader to initial state
   *
   * Use with caution, will kill all ongoing http uploads and reset file uploader
   *
   * Example of use: Can be used by component to force reset file uploader when naviguating to other page when uploads are still ongoing and page is using reuseCacheStrategy.
   */
  public resetFileUploader(): void {
    this.isReadyToUpload.next(false);
    this.isUploading.next(false);
    this.resetBatch();
    this.cancelAllOngoingUploadRequests();
    this.displayFiles = [];
    this.resetBatch();
  }

  /** Actions executed each time user add files to the dropzone or trough the input type="file"
   *
   * @param files
   */
  public onDrop(files: Nullable<FileList>): void {
    // stop if disabled
    if (this.disableUploader || files === null) {
      return;
    }

    // If multi files not allowed and one is already there, clear array and let override
    if (!this._allowMultiUpload && this.displayFiles.length > 0) {
      this.resetBatch();
    }

    // If asked to keep confirmation of upload, erase successfully uploaded file on new drops
    if (this._showUploadedFiles) {
      this.displayFiles = this.displayFiles.filter((rsFile: RsFile): boolean => {
        return rsFile.progress < 100;
      });
    }

    for (let index = 0; index < files.length; index++) {
      this.addFileToUploader(files[index]);

      // If no multifiles allowed stop loop
      if (!this._allowMultiUpload) {
        break;
      }
    }

    if (this.displayErrorDialog) {
      this.openErrorDialog();
      this.isReadyToUpload.next(false);
    } else {
      this.isReadyToUpload.next(true);
    }

    this.fileInput.nativeElement.value = '';
  }

  /** Removes files from displayFiles (before upload)
   * adapt the currentBatchSizeInBytes
   *
   * @param RsFile
   * @param removeFromDisplay boolean (should remove file from displayed list when uploaded)
   */
  public removeFileFromQueue(fileToRemove: RsFile, removeFromDisplay = false): void {
    this.fileInput.nativeElement.value = '';
    this.filesValidatedToUploadList = this.filesValidatedToUploadList.filter((file): boolean => {
      return file !== fileToRemove;
    });

    if (removeFromDisplay) {
      this.displayFiles = this.displayFiles.filter((file): boolean => {
        return file !== fileToRemove;
      });
    }

    this.setCurrentBatchSizeInBytes(-fileToRemove.data.size);

    if (this.filesValidatedToUploadList.length === 0) {
      this.isReadyToUpload.next(false);
    }

    this.isBatchDone();
    this.fileRemoved.emit(fileToRemove);
    this.filesChange.next(this.filesValidatedToUploadList);
  }

  public openErrorDialog(): void {
    this.errorDialogRef = this.dialog.open(this.errorDialog, {
      width: '500px',
      panelClass: 'rs-dropzone-error-dialog'
    });

    this.errorDialogRef.afterClosed().subscribe((): void => {
      // Clear filesNotAllowedToUploadList & emit isReadyToUpload
      this.filesNotAllowedToUploadList = [];
      this.displayErrorDialog = false;

      if (this.filesValidatedToUploadList.length > 0) {
        this.isReadyToUpload.next(true);
      }
    });
  }

  /** Return last part of mime type to display nicely on the dropzone helper area
   * @param string Mime type
   */
  public stripMimeTypes(text: string): string | null {
    const reg = text.match(/\/([^/]+)\/?$/);
    return reg ? reg[1] : null;
  }

  /** Upload documents one by one
   * Removes them from the queue
   *
   * @param url string
   * @param formData FormData ´formated as requested by your endpoint´
   * @param rsFile RsFile that you got from getUploaderFileAsArray(true)
   * @param successCallBack (event: HttpResponse<Object>, rsFile: RsFile) => boolean successCallBack create to handle 200 errors.Say again ? Yeaaaah I know :(Thanks RENTA to please BMW >> Ask Dardinho
   * @param errorCallBack (error: any, rsFile: RsFile) => void errorCallBack create to handle 400 errors
   */
  public uploadDocument(
    url: string,
    formData: FormData,
    rsFile: RsFile,
    successCallBack?: (event: HttpResponse<Object>, rsFile: RsFile) => boolean,
    errorCallBack?: () => void
  ): void {

    if (!url) {
      throw new Error('You\'re asking to upload documents without providing an upload url :)');
    }

    if (this.filesValidatedToUploadList.length === 0) {
      return;
    }

    // set isReadyToUpload to false so the submit button is greyed out when
    this.isReadyToUpload.next(false);

    /** ongoingFileUploads = number of files being uploaded */
    this.batchEndCounter = this.filesValidatedToUploadList.length;

    const filesSuccessful: string[] = [];

    // start the upload and save the progress map

    // Reset file error if user try to upload it again after an error
    rsFile.error = false;

    this.isUploading.next(true);

    // rsFile.description = this.fileDescription;

    // Storing observable to be able to cancel the http request
    rsFile.httpRequestSubscriptionHolder$ =
      this.fileUploaderService.upload(
        url,
        formData
      ).pipe(
        takeUntil(this.killAllRequests$)
      ).subscribe({
        next: (event): void | boolean => {

          const uploadProgress = this.fileUploadProgress(event as HttpProgressEvent);
          rsFile.progress = uploadProgress;

          // event.type === 4 means The full response including the body was received.
          // For event types check: https://angular.io/api/common/http/HttpEventType#DownloadProgress
          if (event.type === 4 && (event as HttpResponse<HttpResponseBase>).ok) {

            if (
              successCallBack &&
              !successCallBack(event, rsFile)
            ) {
              return false;
            }

            rsFile.progress = 100;
            rsFile.httpResponse = event as HttpResponse<HttpResponseBase>;
            filesSuccessful.push(rsFile.data.name);

            setTimeout((): void => {
              this.removeFileFromQueue(rsFile, !this._showUploadedFiles);
              // actions when upload succeeded
              this.fileUploadEndedEmitter.emit(rsFile);
            }, 1000);
          }
        },
        error: (error): void => {
          rsFile.error = true;
          rsFile.errorDescription = 'Http upload error';
          rsFile.httpResponse = error;
          // actions when upload does not succeed
          this.fileUploadEndedEmitter.emit(rsFile);

          if (this._showErrorsWithRsMessageHandler) {
            this.rsMessagesHandlerService
              .showBeErrorMsg({
                httpError: error
              });
          }

          this.isBatchDone();
          errorCallBack && errorCallBack();
          if (error === 'Something bad happened. Please try again later.') {
            const errorMsg = (this.translations || {})['FILE_UPLOAD_COMPONENT.FILE_UPLOAD_FAILURE'];
            throwError(() => errorMsg);
          }
        }
      });
  }

  public async serializeUpload(url: string, docs: { formData: FormData, file: RsFile }[]): Promise<void> {
    for (const doc of docs) {
      await this.asyncUploadDocuments(
        url,
        doc.formData,
        doc.file
      );
    }

    this.handleBatchDone();
  }

  /** Returns files as File[]
   *
   * @param { boolean } returnRsFiles boolean
   *
   * @return { (File|RsFile)[] } ( File | RsFile )<Array>
   *
   * @returnRule `if param returnRsFiles == (false|null) ? File[] : RsFile[]`
   */
  public getUploaderFileAsArray(returnRsFiles: boolean = false): (File | RsFile)[] {
    const files: (File | RsFile)[] = [];

    this.filesValidatedToUploadList.forEach((rsFile: RsFile): void => {
      returnRsFiles ? files.push(rsFile) : files.push(rsFile.data);
    });

    return files;
  }

  /** Cancel file upload
   * Removes File From Queue
   * Emit fileUploadEndedEmitter with rsFile error
   * Check if batch done
   *
   * @param { RsFile } RsFile File to cancel upload
   */
  public cancelHttpRequest(rsFile: RsFile): void {
    rsFile.httpRequestSubscriptionHolder$.unsubscribe();
    this.removeFileFromQueue(rsFile, true);

    rsFile.error = true;
    rsFile.errorDescription = 'Canceled by user';
    this.fileUploadEndedEmitter.emit(rsFile);

    rsFile.progress = 0;
  }

  /** Completes killAllRequests$ subject to kill all ongoing uploads */
  public cancelAllOngoingUploadRequests(): void {
    this.killAllRequests$.next(true);
    this.killAllRequests$.complete();
  }

  /** Upload async document one by one
   *
   * @param url string
   * @param formData FormData ´formated as requested by your endpoint´
   * @param file RsFile that you got from getUploaderFileAsArray(true)
   */
  private async asyncUploadDocuments(url: string, formData: FormData, file: RsFile): Promise<void> {
    await new Promise<void>((resolve) => {
      this.uploadDocument(
        url,
        formData,
        file,
        (_event, rsFile) => {
          resolve();
          return !rsFile.error;
        },
        () => { resolve(); }
      );
    });
  }

  /** Check if tmpFile type is allowed.
   *
   * @param RsFile
   * @return RsFile:
   * - If allowed returns RsFile
   * - Else flags RsFile.error = true & RsFile.isFileTypeAllowed = false
   */
  private checkFileType(tmpFile: RsFile): RsFile {

    if (
      this.acceptedFileTypes &&
      this.acceptedFileTypes.length > 0 &&
      !this.acceptedFileTypes.includes(tmpFile.data.type as MimeTypes)
    ) {
      tmpFile.isFileTypeAllowed = false;
      tmpFile.error = true;
    }

    return tmpFile;
  }

  /** Check if the current batch size does not exceed the maxFilesize.
   *
   * @param RsFile
   * @return RsFile:
   * - If not increase this.maxFilesize & returns RsFile
   * - Else flags RsFile.error = true & RsFile.maxFilesSizeExceeded = true
   */
  private checkBatchSize(tmpFile: RsFile): RsFile {
    if ((this.currentBatchSizeInBytes + tmpFile.data.size) > this.maxFileSizeInBytes) {
      tmpFile.maxFilesSizeExceeded = true;
      tmpFile.error = true;
    }

    return tmpFile;
  }

  /** set new value of currentBatchSizeInBytes
   *
   * @param fileSize: number in bytes add - for minus
   */
  private setCurrentBatchSizeInBytes(fileSize: number): void {
    this.currentBatchSizeInBytes += fileSize;
  }

  /** Reset file batch */
  private resetBatch(resetDisplayedFiles = true): void {
    this.currentBatchSizeInBytes = 0;
    this.filesNotAllowedToUploadList = [];
    this.filesValidatedToUploadList = [];
    this.isReadyToUpload.next(false);
    this.cancelAllOngoingUploadRequests();
    if (resetDisplayedFiles) {
      this.displayFiles = [];
    }
  }

  /** Add files
   *
   * @param FileList
   */
  private addFileToUploader(file: File): void {
    let rsFile = new RsFile();
    rsFile.data = file;
    rsFile = this.checkFileSize(rsFile);
    rsFile = this.checkFileType(rsFile);
    rsFile = this.checkMaxFiles(rsFile);
    rsFile = this.checkBatchSize(rsFile);

    if (rsFile.error) {
      this.filesNotAllowedToUploadList.push(rsFile);
      this.displayErrorDialog = true;
    } else {
      this.setCurrentBatchSizeInBytes(rsFile.data.size);
      this.filesValidatedToUploadList.push(rsFile);
      this.displayFiles.push(rsFile);
    }

    this.fileAdded.emit(rsFile);
    this.filesChange.next(this.filesValidatedToUploadList);
  }

  /** transform progress object to percentage
   *
   * @param progress_object { loaded: number; total: number; type: number }
   */
  private fileUploadProgress(event: HttpProgressEvent): number {
    const progress = Math.round(100 * event.loaded / event.total!);
    return progress === 100 ? 99 : progress;
  }

  /** CheckIf batch done and emit done or done with error event */
  private isBatchDone(): void {
    this.batchEndCounter--;

    // Batch ended
    if (this.batchEndCounter === 0) {
      this.handleBatchDone();
    }
  }

  private handleBatchDone(): void {
    // Successful
    if (this.filesValidatedToUploadList.length === 0) {
      this.isReadyToUpload.next(false);
      this.isUploading.next(false);
      this.uploadDone.emit({
        done: true,
        withErrors: false
      });
      this.resetBatch(!this._showUploadedFiles);
    } else { // With errors
      this.isReadyToUpload.next(true); // In case user wants to retry
      this.isUploading.next(false);
      this.uploadDone.emit({
        done: true,
        withErrors: true
      });
    }
  }

  /**  Check maximum size allowed for one file.
   *
   * @param tmpFile
   * @private
   */
  private checkFileSize(tmpFile: RsFile): RsFile {
    if (tmpFile.data.size === 0) {
      tmpFile.isFileEmpty = true;
      tmpFile.error = true;

      return tmpFile;
    }

    if (this.maxSizePerFile && this._allowMultiUpload) {
      if (tmpFile.data.size > this.maxSizePerFileInBytes) {
        tmpFile.maxFileSizeExceeded = true;
        tmpFile.error = true;
      }
    }

    return tmpFile;
  }

  /** Check maximum number files
   *
   * @param tmpFile
   * @private
   */
  private checkMaxFiles(tmpFile: RsFile): RsFile {
    if (this.maxTotalFiles && this._allowMultiUpload) {
      if ((this.filesValidatedToUploadList.length + 1) > this.maxTotalFiles) {
        tmpFile.maxTotalFilesExceeded = true;
        tmpFile.error = true;
      }
    }

    return tmpFile;
  }

  /** Transform megabytes (base 10) to bytes (base 2)
   *
   * @param megabytes
   */
  private transformMBToBytes(megabytes: number): number {
    return Number((megabytes * 1024 * 1024).toFixed(9));
  }

  private displayBytesLabels(bytes: number): string {
    if (bytes >= 1000) {
      return (bytes / 1000) + 'MB';
    }
    return bytes + 'KB';
  }
}
