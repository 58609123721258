@if (auditTrail()) {
<ct-audit-trail>
  @if (auditTrail().createdOn) {
  <ct-created-on data-testid="audit-trail-created">{{ 'CREATED_BY' | translate }} {{ auditTrail().createdBy | rsCreationEditionUser | rsNullDash }}
    @if (multiline()) {<br />}{{ 'ON' | translate }} {{ auditTrail().createdOn | date:'short':'': translateService.currentLang }}</ct-created-on>
  }
  @if (auditTrail().lastModifiedOn) {
  <ct-modified-on data-testid="audit-trail-modified">{{ 'LAST_UPDATED_BY' | translate }} {{ auditTrail().lastModifiedBy | rsCreationEditionUser | rsNullDash }}
    @if (multiline()) {<br />}{{ 'ON' | translate }} {{ auditTrail().lastModifiedOn | date:'short':'': translateService.currentLang }}</ct-modified-on>
  }
</ct-audit-trail>
}