export enum UserInfoCookieAppName {
    FMS = 'FMS',
    MRT = 'MRT',
    MRT_FR = 'MRT',
    MRT_DASHBOARD = 'MRT_DASHBOARD',
    ORD = 'ORD',
    ORD_ADMIN = 'ORD_ADMIN',
    EOC = 'EOC',
    DIVC = 'DIVC',
    REI = 'REI',
    MGT = 'MGT'
}
