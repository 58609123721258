<section class="file-uploader-container pt-1px ">

  <!-- UPLOADER -->
  <section
    data-testid="rs-file-dropzone"
    (dropped)="onDrop($event)"
    (hovered)="toggleHover($event)"
    [class.disabled]="disableUploader"
    [class.hovering]="isHovering && !disableUploader"
    class="rs-dropzone position-relative"
    rsDropzone
  >
    <div [ngClass]="{'file-uploader-disabler': disableUploader}"></div>

    <ct-content-area>
      <mat-icon
        rs-icon-outline
        class='cloud-icon'
        fontIcon="cloud_upload"
      />

      <ct-upload-instructions>
        {{ "FILE_UPLOAD_COMPONENT.DRAG_AND_DROP" | translate }}
        <span rs-font="light"> {{ "FILE_UPLOAD_COMPONENT.YOUR_FILES_HERE_OR" | translate }} </span>
        <a (click)="fileInput.click()">{{ "FILE_UPLOAD_COMPONENT.BROWSE" | translate }} {{ "FILE_UPLOAD_COMPONENT.FILES" | translate }}</a>
      </ct-upload-instructions>

      <ct-file-types>
        @if (acceptedFileTypes && acceptedFileTypes.length > 0) {
        {{ 'FILE_UPLOAD_COMPONENT.ACCEPTED_FILE_TYPES' | translate }}

        <ct-types>
          @for (file of acceptedFileTypes; track file) {
          <span [attr.data-mime-type]="stripMimeTypes(file)">
            {{ enumMimeTypes[file] || stripMimeTypes(file) }}
          </span>
          }
        </ct-types>
        }
      </ct-file-types>

      <ct-file-sizes>
        @if (maxFiles) {
        <span>{{ 'FILE_UPLOAD_COMPONENT.MAX_FILES' | translate }}: {{ this.maxFiles }}</span>
        }

        @if (allowMultiUpload && maxSizePerFile) {
        <span>{{ 'FILE_UPLOAD_COMPONENT.MAX_FILE_SIZE' | translate }}: {{ maxFileSizePerFileDisplay }}</span>
        }

        @let multipleFilesLabel = allowMultiUpload ? 'FILE_UPLOAD_COMPONENT.MAX_TOTAL_SIZE' : 'FILE_UPLOAD_COMPONENT.MAX_FILE_SIZE';
        <span>{{ multipleFilesLabel | translate }}: {{ maxTotalFilesizeDisplay }}</span>
      </ct-file-sizes>
    </ct-content-area>

    <input
      #fileInput
      (change)="onDrop(fileInput.files)"
      [multiple]="allowMultiUpload"
      class="file-input"
      type="file"
    >
  </section>

  <!-- FILES TO UPLOAD -->
  @if (displayFiles.length > 0) {
  <section class="rs-files-to-upload-zone mb-30px">
    @for (file of displayFiles; track file) {
    <div
      [ngClass]="{ 'upload-successful': file.progress == 100}"
      class="file-item row align-items-center g-0"
    >
      <div class="col-auto position-relative">
        <mat-icon class="done-icon">
          done
        </mat-icon>
        @if ((!file.error || file.progress == 100)) {
        <mat-icon
          [ngClass]="{ 'is-uploading': (file.progress > 0 && file.progress < 100) }"
          class="file-icon"
          svgIcon="file-outline"
        >
        </mat-icon>
        }
        @if ((file.error && file.progress != 100)) {
        <mat-icon
          [matTooltip]="'FILE_UPLOAD_COMPONENT.FILE_UPLOAD_FAILURE' | translate"
          class="file-icon upload-error"
          matTooltipClass="tooltip-error"
          matTooltipPosition="right"
        >
          error
        </mat-icon>
        }
      </div>
      <div class="col text-truncate col-file-name">
        {{ file.data.name }}
      </div>
      @if (_showUploadProgress) {
      <div class="col-auto">
        <mat-progress-bar
          [ngClass]="{ 'upload-successful': file.progress == 100, 'upload-error': (file.error && file.progress < 100)  }"
          [value]="file.progress"
          class="rs-progress-bar"
          mode="determinate"
        >
        </mat-progress-bar>
      </div>
      }
      <div class="col-auto col-edit-icons">
        @if (file.progress !== 100 && (file.progress === 0 || file.error) && !isExternalUploading) {
        <button
          (click)="removeFileFromQueue(file, true);"
          [attr.data-file-name]="file.data.name"
          [attr.data-file-id]="file.id"
          data-testid="button-delete-file"
          mat-icon-button
          type="button"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
        }
        @if (file.progress !== 100 && file.progress !== 0 && !file.error) {
        <button
          (click)="cancelHttpRequest(file)"
          mat-icon-button
          type="button"
        >
          <mat-icon>clear</mat-icon>
        </button>
        }
      </div>
    </div>
    }
  </section>
  }
</section>

<ng-template #errorDialog>
  <h2 matDialogTitle>{{ 'FILE_UPLOAD_COMPONENT.ERROR_DIALOG_TITLE' | translate }}</h2>

  <div
    mat-dialog-content
    data-testid="rs-file-error-dialog"
  >
    <ul class="file-error-list p-0 m-0">
      @for (file of filesNotAllowedToUploadList; track file) {
      <li>
        <button
          (click)="file['display-error-info']=!file['display-error-info']"
          class="rs-btn--text"
          mat-button
          type="button"
        >
          <mat-icon iconPositionStart>info</mat-icon>
          <span class="rs-font-size-12px">{{ file.data.name }}</span>
        </button>
        <ul
          [ngClass]="{ 'open': file['display-error-info'] }"
          class="ms-24px"
        >
          @if (file.isFileEmpty) {
          <li>
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_EMPTY_FILE' | translate }}
          </li>
          }
          @if (!file.isFileTypeAllowed) {
          <li>
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_FILE_TYPE_NOT_ALLOWED' | translate }}
          </li>
          }
          @if (file.maxFilesSizeExceeded) {
          <li>
            {{ (allowMultiUpload ? 'FILE_UPLOAD_COMPONENT.ERROR_BATCH_SIZE_EXCEEDED' : 'FILE_UPLOAD_COMPONENT.ERROR_FILE_SIZE_EXCEEDED') | translate }}
          </li>
          }
          @if (file.maxFileSizeExceeded) {
          <li>
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_FILE_SIZE_EXCEEDED' | translate }}
          </li>
          }
          @if (file.maxTotalFilesExceeded) {
          <li>
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_TOTAL_FILES_EXCEEDED' | translate }}
          </li>
          }
        </ul>
      </li>
      }
    </ul>
  </div>

  <mat-dialog-actions align="end">
    <button
      (click)="this.errorDialogRef.close()"
      mat-button
    >
      Ok
    </button>
  </mat-dialog-actions>
</ng-template>