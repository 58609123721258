import { Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { CreationEditionUserPipe } from '../../pipes/creation-edition-user/creation-edition-user.pipe';
import { AuditTrail } from '../../models/audit';
import { RsNullDashPipe } from '../../pipes/rs-null-dash/rs-null-dash.pipe';

@Component({
  selector: 'rs-audit-trail',
  templateUrl: './rs-audit-trail.component.html',
  styleUrls: ['./rs-audit-trail.component.scss'],
  imports: [
    TranslateModule,
    DatePipe,
    CreationEditionUserPipe,
    RsNullDashPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  host: { class: 'rs-audit-trail' }
})
export class RsAuditTrailComponent {
  public readonly auditTrail = input.required<AuditTrail>();
  public readonly multiline = input(true);

  constructor(public readonly translateService: TranslateService) {
  }
}
