import { Environment } from './custom-types';

export interface SubMenuItem {
  url: string;
  label: string;
  doNotTranslateLabel?: boolean;
  labelSuffix?: string;
  display?: boolean;
  /** Used by roots to select menuItem */
  id: string;
  /** Display subMenuItem only on specified environments */
  rsIfEnvironment?: Environment[];
  baseMenuUrl?: string[];
}

export interface MenuItem {
  url?: string;
  label: string;
  doNotTranslateLabel?: boolean;
  labelSuffix?: string;
  display?: boolean;
  subMenuItems?: SubMenuItem[] | undefined;
  /** Used by roots to select menuItem */
  id: string;
  /** Display menuItem only on specified environments */
  rsIfEnvironment?: Environment[];
  baseMenuUrl?: string[];
}
