export function rsDownloadDocumentHandler(
    blob: Blob | null,
    fileName: string
): void {
  if (!blob) { console.error('No document to download. Blob is null.'); return; }

  const element = document.createElement('a');

  try {
    // Create a link to add a custom file name when downloading
    element.href = URL.createObjectURL(blob);
    element.download = fileName;

    // Append to DOM and trigger the download
    document.body.appendChild(element);
    element.click();
  } catch (error) {
    console.error('Error occurred while downloading the document:', error);
  } finally {
    // Cleanup: remove the element and revoke the object URL
    if (document.body.contains(element)) {
      document.body.removeChild(element);
      URL.revokeObjectURL(element.href);
    }
  }
}