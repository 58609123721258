import { Pipe, PipeTransform } from '@angular/core';
import { Address, DeliveryAddress, NewAddress, StreetAndHouseNumberAddress } from './models/address';
import { Nullable } from '../../models';

@Pipe({
  name: 'newAddress',
  standalone: true
})
export class NewAddressPipe implements PipeTransform {
  public transform(value?: Nullable<NewAddress>): string {
    if (!value) {
      return '';
    }
    return `${value.address}, ${value.postalCode} ${value.city}`.trim();
  }
}

@Pipe({
  name: 'address',
  standalone: true
})
export class AddressPipe implements PipeTransform {
  public transform(value?: Nullable<StreetAndHouseNumberAddress>): string {
    if (!value) {
      return '';
    }
    const streetAndHouseNumber = value.streetAndHouseNumber ? value.streetAndHouseNumber : '';
    const city = value.city ? value.city : '';
    const postalCode = value.postalCode ? value.postalCode : '';
    return `${streetAndHouseNumber} ${city} ${postalCode}`.trim();
  }
}

@Pipe({
  name: 'postalAddress',
  standalone: true
})
export class PostalAddressPipe implements PipeTransform {
  public transform(
    value?: Nullable<{
      city?: string;
      boxNumber?: string;
      houseNumber?: string;
      postalCode?: string;
      street?: string;
    }>
  ): string {
    if (!value) {
      return '';
    }

    const streetAndHouseNumber = value.street ? `${value.street} ${value.houseNumber}` : '';
    const boxNumber = value.boxNumber ? ` ${value.boxNumber}` : '';
    const city = value.city ? value.city : '';
    const postalCode = value.postalCode ? `, ${value.postalCode}` : '';
    return `${streetAndHouseNumber}${boxNumber}${postalCode} ${city}`.trim();
  }
}

@Pipe({
  name: 'formatDeliveryAddress',
  standalone: true
})
export class FormatDeliveryAddressPipe implements PipeTransform {

  public transform(address: DeliveryAddress): string | null {
    const personOrCompany = `${address?.firstName ?? ''} ${address?.lastNameOrCompanyName}`.trim();
    const street = new PostalAddressPipe().transform(address as Address);

    return (!personOrCompany || !street) ? null : `${personOrCompany}, ${street}`;
  }

}
